import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import BannerImage from '../components/elements/BannerImage'
import banner from '../images/slicing/home/banner.png'
class BlockChainContainer extends Component {
    render() {
        return (
            <>
                <BannerImage
                    activeTab="Case Study"
                    bannerImage={banner}
                    text=" Real time tagging, validation for global logistics company using Blockchain" />
                <Container fluid>
                    <Container className="py-5 border-bottom">
                        <Row>
                            <Col>
                                <h3 className="font-weight-bold">
                                    <span style={{ borderBottom: '2px', borderBottomColor: '#eebb6b', borderBottomStyle: 'solid' }}>The</span> Customer
                                    </h3>
                                <p className="industries-paragrapgh pt-3">
                                    Our client is one of the major code generations and monitoring
                                    companies for various products in the Healthcare and FMCG sector.
                            </p>
                                <p className="industries-paragrapgh pt-3">
                                    They generate codes that are being used as Batch Number
                                    or other identificationnumbers to identify these products
                                    individually in the market. These codes are also being
                                    used to check the authenticity of the product to prevent counter feiting.
                            </p>
                                <p className="industries-paragrapgh pt-3">
                                    Wants to solve problems in their existing process so that they
                                    can involve more parties in the process to build the track and
                                    trace capabilities for any individual code generated,
                                    sent, printed, or being checked at any point in the process.
                            </p>
                            </Col>
                            <Col>
                                <h3 className="font-weight-bold">
                                    <span
                                        style={{ borderBottom: '2px', borderBottomColor: '#eebb6b', borderBottomStyle: 'solid' }}>Key</span> Information
                                    </h3>
                                <Container className="pt-3">
                                    <Row>
                                        <Col className="standard-background mt-2 p-3">
                                            <h3>Platform Scale</h3>
                                            <p>Global SaaS platform used by 500+ zoos, aquariums and private animal
                                                 conservationist across the globe.</p>
                                        </Col>
                                        <Col className="standard-background ml-2 mt-2 p-3">
                                            <h3>Duration</h3>
                                            <p>24 months</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="standard-background mt-2 p-3">
                                            <h3>Delivery Methodology</h3>
                                            <p>SAFE (Scaled Agile Framework)</p>
                                        </Col>
                                        <Col className="standard-background ml-2 mt-2 p-3">
                                            <h3>GTEN Role</h3>
                                            <p>Platform Development & Blockchain Implementation</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>

                    <Container className="py-5 border-bottom">
                        <Row>
                            <Col>
                                <h3 className="font-weight-bold">
                                    <span
                                        style={{ borderBottom: '2px', borderBottomColor: '#eebb6b', borderBottomStyle: 'solid' }}>Key</span> Challenge
                                    </h3>
                                <p className="industries-paragrapgh pt-3">
                                    The existing process was a hybrid process including some automated and some manual steps, which are as follows:
                            </p>
                                <p className="industries-paragrapgh">
                                    • Every manufacturing plant has one software deployed and connected to the printer.
                            </p>
                                <p className="industries-paragrapgh">
                                    • Codes were uploaded manually, and software send instructions to the printer.
                            </p>
                                <p className="industries-paragrapgh">
                                    • The printer generates a printing report.
                            </p>
                                <p className="industries-paragrapgh">
                                    • This printing report was uploaded to the tracking portal.
                            </p>

                                <p className="industries-paragrapgh pt-3">
                                    When carefully analysed the existing process, we figure out the following issues which needed to fix into the process:
                            </p>
                                <p className="industries-paragrapgh">
                                    • Multiple points of failures.
                            </p>
                                <p className="industries-paragrapgh">
                                    • Unable to scale.
                            </p>
                                <p className="industries-paragrapgh">
                                    • High management cost.
                            </p>
                                <p className="industries-paragrapgh">
                                    • Vulnerable and prone to fail.
                            </p>
                                <p className="industries-paragrapgh">
                                    • Lots of manual processes.
                            </p>
                                <p className="industries-paragrapgh">
                                    • Offline dependencies.
                            </p>
                            </Col>


                        </Row>

                    </Container>
                    <Container className="py-5 border-bottom">
                        <Row>
                            <Col>
                                <h3 className="font-weight-bold">
                                    <span
                                        style={{ borderBottom: '2px', borderBottomColor: '#eebb6b', borderBottomStyle: 'solid' }}>GTEN</span> Solution
                                    </h3>
                                <p className="industries-paragrapgh pt-3">
                                    Our solution was based on solving three crucial problems in the existing process
                            </p>
                                <p className="industries-paragrapgh">
                                    1. Remove offline dependencies and make it cloud-based.<br />
                                    2. Scaling possibilities when multiple parties are being involved.<br />
                                    3. Automate the manual process to make it less vulnerable.
                            </p>
                                <p className="industries-paragrapgh">
                                    The very first version of the solution was to have EPCIS
                                    developed and set up for all the involved parties and then
                                    move their transaction events into distributed ledgers on Blockchain.
                            </p>
                                <p className="industries-paragrapgh">
                                    The solution is divided into three major parts<br />
                                    1. Central Dashboard — cloud-based web application.<br />
                                    2. Capture — IoT based desktop application.<br />
                                    3. Distributed Event Ledger — Hyperledger based distributed ledger of events.
                            </p>



                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className="industries-paragrapgh">
                                    <b className="text-standard">Central Dashboard: </b>  The central dashboard is
                                     being used to manage products, vendors, manufacturers, packaging’s,
                                     codes, and EPCIS Events.<br />

                                    <span className="text-standard">Followings are the modules in the central dashboard:</span><br />
                                     • <b>Products</b> — manage all the products involved in EPCIS flow.<br />
                                     • <b>Location</b> — locations are the addresses of the plant, vendor or manufacturer.<br />
                                     • <b>User</b> — users are the admin, CMO, vendor or manufacturer•Code — codes are batch numbers for the various level of packaging like primary secondary, tertiary, etc.<br />
                                     • <b>Events and Actions</b> — events generated on each action taken on any above modules along with the action taken on those events. <br />

                                    <span className="text-standard">The technologies used to build the central dashboard are:</span><br />
                                     • <b>NodeJs</b> — used to build RESTful APIs for central dashboard.<br />
                                     • <b>React</b> — used to build the UI layer of the central dashboard.
                            </p>
                            </Col>
                            <Col>
                                <p className="industries-paragrapgh">
                                    <b className="text-standard">Capture: </b>  The capture is being used to capture
                                    data and installed at various locations, it also has IoT integration with various
                                     sensors and scanners.
                                    <br /><br />Every vendor and manufacturer needed to install the capture
                                     desktop application with required drivers for their
                                      sensors and scanners to generate events.<br />
                                    <span className="text-standard">The technology used to build capture</span> <br />
                                      • <b>NodeJS</b> — IoT drivers.<br />
                                      • <b>Electrons</b> — desktop app interface for windows platform.


                            </p>
                            </Col>
                            <Col>
                                <p className="industries-paragrapgh">
                                    <b className="text-standard">Distributed Event Ledger: </b>  The distributed event ledger is
                                     the EPCIS events ledger distributed among all the involved parties
                                      (vendors, manufacturers, CMO, and admin) using a private blockchain.
                                    <br /><br />Each time an event is recorded a new block
                                     is created which goes to their assigned parties for
                                      verification and added to the chain once verified.
                                       The verification level is private and only involved
                                       the related plant and product along with the vendor
                                       and manufacturer.<br />
                                    <span className="text-standard">The technology used to build distributed event ledger</span> <br />
                                      • <b>Hyperledger Composer</b> — to compose the private blockchain network.<br />
                                      • <b>Hyperledger Explorer</b> — to explore, verify or reject the event inside the network.

                            </p>
                            </Col>
                        </Row>
                    </Container>
                    <Container className="py-5">
                        <Row>
                            <Col>
                                <h3 className="font-weight-bold">
                                    <span
                                        style={{ borderBottom: '2px', borderBottomColor: '#eebb6b', borderBottomStyle: 'solid' }}>Benefits</span> Achieved
                                    </h3>
                                <p className="industries-paragrapgh pt-3">
                                    Following benefits were delivered to customer:
                                </p>

                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <ul>
                                    <li> Real time onboarding of vendors, products .</li>
                                    <li> Delivery workflow with package tagging by location, vendor, cargo ships with visibility at every stage.</li>
                                    <li>Verification and confirmation of delivery at every intermediate step with dashboard visibility.</li>
                                    <li> Geotagging of packages and visibility on a dashboard.</li>
                                </ul>

                            </Col>



                        </Row>
                    </Container>
                    <hr />
                </Container>

            </>
        )
    }
}

export default BlockChainContainer