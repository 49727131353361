import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import BlockChainContainer from "../containers/BlockChainContainer"

const BlockChain = (props) => (
  <Layout 
  pageInfo={{ pageName: "Block Chain" }} 
  styleClass="home-page" 
  location={'/blockchain/'}>
    <SEO title="Block Chain" />
    <BlockChainContainer />
  </Layout>
)

export default BlockChain
